import {ActionFunctionArgs} from '@remix-run/node';
import {Form, Link, LinkProps, redirect, useNavigation} from '@remix-run/react';
import {useId} from 'react';
import {Button} from '~/components/ui/button';
import {Checkbox} from '~/components/ui/checkbox';
import {Input, InputProps} from '~/components/ui/input';
import {Label} from '~/components/ui/label';
import {Spinner} from '~/components/ui/spinner';
import {cn} from '~/components/utils';
import {services} from '~/services/services.server';
import {remixCaller} from '../api.$trpc/api.server';

export const action = async ({request}: ActionFunctionArgs) => {
  try {
    const data = await services.form.getRequestData(request);
    const fetcher = await remixCaller(request);
    const checkout = await fetcher.customer.createCheckout(data);
    return redirect(checkout.url);
  } catch (error) {
    return {error: {message: 'Mot de passe ou email incorrect'}};
  }
};

export default function RegisterPage() {
  const navigation = useNavigation();
  const isLoading = navigation.state !== 'idle';
  return (
    <AuthForm title="Inscription">
      <div className="flex flex-col sm:flex-row gap-6">
        <AuthField className="flex-[2]" label="Quel est votre email ?" required name="email" type="email" />
        <AuthField
          className="flex-1"
          label="Nombre de licences"
          required
          name="licenses"
          type="number"
          defaultValue="1"
          min="1"
        />
      </div>
      <div className="flex flex-col sm:flex-row gap-6">
        <AuthField className="flex-1" label="Prénom" required name="firstname" />
        <AuthField className="flex-1" label="Nom" required name="lastname" />
      </div>

      <AuthCheckbox required name="cgv" label="J'ai lu et j'accepte les conditions générales de vente" />
      <AuthLink to="/docs/cgv" target="_blank">
        Consulter les CGV
      </AuthLink>
      <Button size="xl" variant="quaternary" type="submit" className="mt-24">
        Je m'inscris{isLoading && <Spinner className="ml-12 size-8" />}
      </Button>
      <AuthLink to="/auth/login" className="text-secondary font-bold">
        Déjà inscrit ?
      </AuthLink>
    </AuthForm>
  );
}

type AuthFieldProps = {
  label: string;
} & InputProps;

export const AuthField = ({label, className, ...props}: AuthFieldProps) => {
  const id = useId();
  return (
    <div className={cn('flex flex-col gap-6', className)}>
      <Label className="font-semibold text-xl" htmlFor={id}>
        {label}
      </Label>
      <Input {...props} id={id} size="xl" />
    </div>
  );
};

type AuthFormProps = {
  title: string;
  children: React.ReactNode;
};
export const AuthForm = ({title, children}: AuthFormProps) => {
  return (
    <Form className="flex flex-col p-12 sm:p-20 gap-6" method="POST">
      <div className="text-5xl font-bold text-center text-primary my-12 sm:my-20">{title}</div>
      {children}
    </Form>
  );
};

export const AuthLink = ({className, ...props}: LinkProps) => {
  return <Link className={cn('text-lg text-gray-400 hover:text-gray-500', className)} {...props} />;
};

type AuthCheckboxProps = {
  name: string;
  required?: boolean;
  className?: string;
  label: string;
};

const AuthCheckbox = ({name, required, className, label}: AuthCheckboxProps) => {
  const id = useId();
  return (
    <div className={cn('flex flex-row gap-4', className)}>
      <Checkbox className="size-6" required={required} id={id} name={name} />
      <Label className="text-lg" htmlFor={id}>
        {label}
      </Label>
    </div>
  );
};
